<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs color="deep-purple accent-4">
            <v-tab>Pending</v-tab>
            <v-tab>Approved</v-tab>
            <v-tab>Damaged</v-tab>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="headerspending"
                        :items="pending_product"
                        :search="search"
                        class="elevation-1"
                        style="background: #f5f5f5"
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <!-- <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >All In</v-toolbar-title
                            > -->

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actionsview="{ item }">
                          <v-btn
                            color="primary"
                            text
                            @click="view(item)"
                            style="padding-left: 0px !important"
                          >
                            Approve
                          </v-btn>

                          <!-- <v-icon small @click="deleteItem(item)">
                            mdi-delete
                          </v-icon> -->
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="approve_headers"
                        :items="approved_product"
                        :search="search"
                        class="elevation-1"
                        style="background: #f5f5f5"
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <!-- <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >All In</v-toolbar-title
                            > -->

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="viewApprove(item)">
                            mdi-eye
                          </v-icon>
                          <!-- <v-icon small @click="deleteItem(item)">
                            mdi-delete
                          </v-icon> -->
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="cancelled_headers"
                        :items="cancelled_product"
                        :search="search"
                        class="elevation-1"
                        style="background: #f5f5f5"
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <!-- <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >All In</v-toolbar-title
                            > -->

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="view(item)">
                            mdi-eye
                          </v-icon>
                          <!-- <v-icon small @click="deleteItem(item)">
                            mdi-delete
                          </v-icon> -->
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>

          <!-- View Details Dialog  -->

          <v-dialog
            v-model="dialog"
            transition="scale-transition"
            origin="top right"
            :nudge-left="500"
            max-width="900px"
            max-height="650"
          >
            <v-card class="pa-5">
              <v-card class="pa-5" elevation="0">
                <v-app-bar absolute color="white" flat>
                  <v-toolbar-title class="font-weight-bold"
                    >Products Detail</v-toolbar-title
                  >

                  <v-spacer></v-spacer>
                  <v-btn
                    class=""
                    icon
                    elevation="0"
                    @click="closedialog"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>

                <v-card class="mx-auto mt-12" elevation="0">
                  <v-row
                    v-if="
                      child_details.Product_image &&
                      child_details.Product_image.length > 0
                    "
                  >
                    <v-carousel
                      cycle
                      height="400"
                      hide-delimiter-background
                      show-arrows-on-hover
                    >
                      <v-carousel-item
                        v-for="(slide, i) in child_details.Product_image"
                        :key="i"
                        :src="slide.image_url"
                      >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                        </v-row>
                      </v-carousel-item>
                    </v-carousel>
                  </v-row>

                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          Product ID :
                          <span class="font-weight-regular">{{
                            child_details.product_specification.product_id
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <p class="font-weight-medium"></p>
                      </v-col>
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          SKU :
                          <span class="font-weight-regular">{{
                            child_details.Product_code.SKU
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="12"
                        ><p class="font-weight-medium">
                          Product Title :
                          <span class="font-weight-regular">{{
                            child_details.Product.title
                          }}</span>
                        </p>
                      </v-col>

                      <!-- <v-col cols="12">
                        <p class="font-weight-medium">
                          Category :
                          <span class="font-weight-regular">{{
                            child_details.category_data.category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Category :
                          <span class="font-weight-regular">{{
                            child_details.category_data.sub_category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Sub Category :
                          <span class="font-weight-regular">{{
                            child_details.category_data.sub_sub_category
                          }}</span>
                        </p>
                      </v-col> -->
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Brand :
                          <span class="font-weight-regular"
                            >{{ child_details.Product.brand }}
                          </span>
                        </p>
                      </v-col>

                      <!-- <v-col cols="6">
                        <p class="font-weight-medium">
                          Warrenty :
                          <span class="font-weight-regular"
                            >{{ child_details.warranty }}
                            {{ child_details.warranty_unit }}</span
                          >
                        </p>
                      </v-col> -->
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Unit :
                          <span class="font-weight-regular">{{
                            child_details.product_specification.unit
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Origin :
                          <span class="font-weight-regular">{{
                            child_details.Product.origin
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Shipping Country :
                          <span class="font-weight-regular">{{
                            child_details.Product.shipping_country
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="12">
                        <p class="font-weight-medium">Product Description</p>
                        {{ child_details.Product.description }}
                      </v-col>

                      <!-- <v-col cols="12">
                        <div>
                          <p class="font-weight-medium">Key Features :</p>

                          <li
                            class="mt-5"
                            v-for="(itemfeatures, index) in child_details
                              .Product.key_features"
                            :key="index"
                          >
                            {{ itemfeatures }}
                          </li>
                        </div>
                      </v-col> -->
                      <v-col cols="6">
                        Color :

                        {{ child_details.product_specification.color }}
                      </v-col>
                      <v-col cols="6" class="pa-5">
                        Size :

                        {{ child_details.product_specification.size }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-card>

            <!-- <v-card class="pa-5">
              <v-card-text class="pt-0" style="background: #f5f5f5">
                <v-card-title class="py-2 px-0 font-weight-bold"
                  >Child Site Shared Product Price</v-card-title
                >
                <v-divider></v-divider>
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Quantity</th>
                        <th class="text-center">Status</th>
                        <th class="text-center">Purchase Price</th>
                      </tr>
                    </thead>
                    <tbody align="center">
                      <tr
                        v-for="(item, index) in child_details.max_min"
                        :key="index"
                      >
                        <td class="text-center">
                          {{ item.quantity }}
                        </td>
                        <td class="text-center">
                          {{ item.status }}
                        </td>
                        <td class="text-center">
                          {{ item.purchase_price }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card> -->

            <v-card class="pa-5">
              <v-card-text class="pt-0">
                <v-form ref="specPrice" v-model="valid_add_price_specification">
                  <v-row>
                    <v-card style="min-width: 100%; border: 1px solid black">
                      <v-card-title class="font-weight-medium pt-2">
                        <p class="mt-5">
                          Total Quantity: {{ child_details.quantity }}
                        </p></v-card-title
                      >
                      <v-card-text>
                        <v-simple-table fixed-header>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-center">Status</th>
                                <th class="text-center">Quantity</th>
                              </tr>
                            </thead>
                            <tbody align="center">
                              <tr
                                v-for="(
                                  item, index
                                ) in child_details.status_data"
                                :key="index"
                              >
                                <td class="text-center">
                                  {{ item.status }}
                                </td>
                                <td class="text-center" width="200">
                                  <v-text-field
                                    v-model="item.quantity"
                                    outlined
                                    dense
                                    class="mt-5"
                                  ></v-text-field>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>

                        <!-- <v-row>
                          <v-col cols="4">
                            <v-text-field
                              v-model="approvedQty"
                              label="Approve Quantity"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="notfoundQty"
                              label="Approve Quantity"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="damagedqty"
                              label="Approve Quantity"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row> -->
                      </v-card-text>
                    </v-card>
                  </v-row>
                  <v-card-actions>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="d-flex flex-row mt-2">
                        <v-btn
                          elevation="0"
                          color="red"
                          @click="CancelApprove(child_details)"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          @click="SaveApprove(child_details)"
                          elevation="0"
                          color="success"
                          :disabled="!valid_add_price_specification"
                        >
                          Approve
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- 
          This dialog for view Approved Product details -->
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    text: "",
    color: "",
    valid_add_price_specification: false,
    snackbar: false,
    search: "",

    dialog: false,
    dialogapproved: false,
    orders: [],
    quantity: 0,
    user: {},
    contact: {},
    sellerPdetails: {},
    child_details: {
      category_data: {},
      Product_code: {},
      Product_image: {},
      product_specification: {},
      Product: {},
      status_data: [
        {
          status: "Approved",
          quantity: 0,
        },
        {
          status: "Not Found",
          quantity: 0,
        },
        {
          status: "Damaged",
          quantity: 0,
        },
      ],
    },

    all_shared_product: [],
    approved_product: [],
    pending_product: [],
    cancelled_product: [],

    approve_headers: [
      {
        text: "Specification ID",
        value: "id",
      },
      { text: "Product Name", value: "product_data.title", sortable: false },
      { text: "Brand", value: "product_data.brand", sortable: false },
      { text: "Color", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      // { text: "Order Status", value: "order_status", sortable: false },
      // { text: "Admin Status", value: "admin_status", sortable: false },
      // { text: "Delivery Status", value: "delivery_status", sortable: false },
      // { text: "Ref.No", value: "reference_id", sortable: false },
      { text: "Details", value: "actions", sortable: false },
    ],
    headerspending: [
      { text: "Product Name", value: "Product.title", sortable: false },

      { text: "Brand", value: "Product.brand", sortable: false },
      { text: "Color", value: "product_specification.color", sortable: false },
      { text: "Size", value: "product_specification.size", sortable: false },
      // { text: "Order Status", value: "order_status", sortable: false },
      // { text: "Admin Status", value: "admin_status", sortable: false },
      // { text: "Delivery Status", value: "delivery_status", sortable: false },
      // { text: "Ref.No", value: "reference_id", sortable: false },
      { text: "Details", value: "actionsview", sortable: false },
    ],
    cancelled_headers: [
      {
        text: "Specification ID",
        value: "id",
      },
      { text: "Product Name", value: "product_data.title", sortable: false },
      { text: "Brand", value: "product_data.brand", sortable: false },
      { text: "Color", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      // { text: "Order Status", value: "order_status", sortable: false },
      // { text: "Admin Status", value: "admin_status", sortable: false },
      // { text: "Delivery Status", value: "delivery_status", sortable: false },
      // { text: "Ref.No", value: "reference_id", sortable:
    ],

    loading: true,
    approvedQty: 0,
    damagedqty: 0,
    notfoundQty: 0,
  }),

  methods: {
    initialize() {
      axios
        .get(`manager/unattend_products/${this.user.user_id}`)
        .then((response) => {
          this.pending_product = response.data.ware_house[0].data;
          // console.log("this is pending request", this.pending_product);
        });
      // Get all invoices of this admin
      // axios.get("productdetails/getunshared_spec/").then((response) => {
      //   this.sellerPdetails = response.data.data;

      //   this.loading = false;
      // });

      // Get all approved invoices of this admin
      // axios
      //   .get("productdetails/childsite_approved_products/")
      //   .then((response) => {
      //     this.approved_product = response.data.data;
      //   });

      // Get all pending invoices of this admin

      // Get all cancelled Product of this admin
      // axios
      //   .get("productdetails/childsite_cancelled_products/")
      //   .then((response) => {
      //     this.cancelled_product = response.data.data;
      //   });
    },

    // Details of an Approved Product
    viewApprove(item) {
      this.dialogapproved = true;
      Object.assign(this.approvedchild_details, item);

      // let routeData =
      // this.$router.push({
      //   name: "Child Product Details",
      //   params: { child : this.child_details },
      // });
    },

    // Details of an Product
    view(item) {
      this.dialog = true;
      Object.assign(this.child_details, item);
      // console.log("this is child details", this.child_details);
    },
    SaveApprove(child_details) {
      let datas = {
        inv_rep_id: this.child_details.inv_rep_id,
        manager_id: this.user.user_id,
        status_data: this.child_details.status_data,
      };
      

      axios
        .post("manager/product_insert/", datas)
        .then((response) => {
          if (response.data.success) {
            this.pending_product = this.pending_product.filter(
              (contact) => contact.id != child_details.id
            );
            this.dialog = false;
            this.ShareProductmodel = false;
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            window.location.reload();
          } else {
            this.text = response.data.message;
            this.color = "red";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong !!";
          //this.text = response.data.message;
          this.color = "error";
          this.snackbar = true;
        });
    },

    closedialog() {
      this.dialog = false;
    },
    CancelApprove(child_details) {},
  },

  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    // console.log("this is user array", this.user);
    this.initialize();
    //this.demo();
    // Fetch all available Districts
    axios.get(`supports/all_areas/`).then((response) => {
      if (response.data.success) {
        this.child_details.districts = response.data.data;
        // keep refernce
        this.districtsArrayForCalculation = response.data.data;

        //  this.districts = [
        //    {
        //      Area_name : "Dhaka"
        //    },
        //    {
        //      Area_name : "B"
        //    },
        //    {
        //      Area_name : "C"
        //    }
        //  ];
        // // keep refernce
        // this.districtsArrayForCalculation = this.districts
      }
    });
  },
};
</script>


<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>




